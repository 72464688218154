/**
 * Sticky Header
 * Adds a class to header on scroll
 */
import magnificPopup from '../vendors/jquery-magnificpopup';
import organicTabs from '../vendors/organic-tab';

jQuery( window ).on( 'load', function() {
	let topBar_height = jQuery( '.top-bar' ).outerHeight();
	let logedin_topBar_height = jQuery( '.top-bar' ).outerHeight();
	const $header_inner = jQuery( '.header-inner' ).outerHeight();

	if ( jQuery( 'body' ).hasClass( 'topbar-hidden' ) ) {
		topBar_height = 0;
		logedin_topBar_height = 0;
	}

	console.log( topBar_height );
	let adjustment = 0;

	jQuery( '.top-bar-cross' ).on( 'click', function() {
		 jQuery( ':root' ).css( '--topbar_height', topBar_height + 'px' );
	} );

	if ( jQuery( 'body' ).hasClass( 'logged-in' ) ) {
		adjustment = 32;
		logedin_topBar_height = topBar_height - adjustment;

		if ( jQuery( window ).width() < 783 ) {
			adjustment = 46;
			logedin_topBar_height = topBar_height - adjustment;
		}
	}
} );

jQuery( function() {
	/**
	 * Toggle menu for mobile
	 */
	const navOverlay = jQuery( '.nav-overlay' );
	const htmlBody = jQuery( 'html, body' );

	jQuery( '.menu-item-has-children > a' )
		.on( 'focus blur', function( e ) {
			jQuery( this ).siblings( '.sub-menu' ).toggleClass( 'focused', e.type === 'focus' );
		} );

	jQuery( '.menu-btn' ).on( 'click', function() {
		jQuery( this ).toggleClass( 'active' );
		navOverlay.toggleClass( 'open' );
		htmlBody.toggleClass( 'no-overflow' );
		jQuery( '.header-nav ul li.active' ).removeClass( 'active' );
		jQuery( '.header-nav ul.sub-menu' ).slideUp();
	} );

    // When hovering over a .project-item a element
    jQuery('.project-item a').on('mouseenter', function() {
        // Add the 'active' class to the cursor dot
        jQuery('.cursor-dot-outline').addClass('active');
    });

    // When mouse leaves the .project-item a element
    jQuery('.project-item a').on('mouseleave', function() {
        // Remove the 'active' class from the cursor dot
        jQuery('.cursor-dot-outline').removeClass('active');
    });

function checkIfInView() {
    var windowHeight = jQuery(window).height(); // Get the window height
    var windowTop = jQuery(window).scrollTop(); // Get the scroll position

    jQuery('.tool-ctn').each(function() {
      var elementTop = jQuery(this).offset().top; // Get the top position of the element

      // Check if the top of the element is 50% from the top of the viewport
      if (elementTop <= windowTop + windowHeight / 2) {
        // Only add the 'active' class if it hasn't been added already
        if (!jQuery(this).hasClass('active')) {
          jQuery(this).addClass('active');
        }
      }
    });
  }

  // Trigger the function when scrolling
  jQuery(window).on('scroll', function() {
    checkIfInView();
  });

  // Initial check in case the page is loaded and the element is already in view
  checkIfInView();

	/**
	 * Add span tag to multi-level accordion menu for mobile menus
	 */

	jQuery( '.menu-item-has-children > a:first-child' ).each( function() {
		jQuery( this ).after( '<span class="submenu-icon"></span>' );
	} );

     jQuery('#contact-form').submit(function(event) {
      event.preventDefault();  // Prevent the default form submission

      // Submit the form data using AJAX to Formspree
      var form = jQuery(this);
      $.ajax({
        url: form.attr('https://formspree.io/f/myzyakbp'),  // Use Formspree's action URL
        type: 'POST',
        data: form.serialize(),  // Serialize the form data
        success: function(response) {
          // If the request is successful, redirect to the "Thank You" page
          window.location.href = 'thanks.html';  // Redirect to the thank you page
        },
        error: function(xhr, status, error) {
          // Handle errors (e.g., Formspree server issues)
          alert('Something went wrong: ' + error);
        }
      });
    });

	/**
	 * Slide Up/Down internal sub-menu when mobile menu arrow clicked
	 */

	jQuery( '.header-nav' ).on( 'click', '.submenu-icon', function() {
		const parentLi = jQuery( this ).closest( 'li' );

		parentLi.siblings( '.active' )
			.removeClass( 'active' )
			.find( 'ul' ).slideUp();

		parentLi.toggleClass( 'active' ).find( 'ul' ).stop( true, true ).slideToggle();
		parentLi.parents( 'ul' ).toggleClass( 'disabled-menu', parentLi.hasClass( 'active' ) );
	} );
	
var swiperOptions = {
  loop: true,
  autoplay: {
    delay: 1,
    disableOnInteraction: false
  },
  speed: 4000,
  breakpoints: {
    0: {
      slidesPerView: 2
    },
    501: {
      slidesPerView: 3
    },
    748: {
      slidesPerView: 4
    },
    1004: {
      slidesPerView: 5
    },
    // Default value for larger screens
    1180: {
      slidesPerView: 6
    }
  }
};

var swiper = new Swiper(".logo-grid-slider", swiperOptions);

} );

  var $tiles = jQuery('.approach-tile');
  var $line = jQuery('.approach-line');
  var $window = jQuery(window);

  // Cache document height and window height once to avoid recalculating on every scroll
  var documentHeight = jQuery(document).height();
  var windowHeight = $window.height();

  // Track if the line has completed drawing
  var lineDrawn = false;

  // Function to check if element is in the 50% viewport height
  function isElementInViewport(el) {
    var rect = el.getBoundingClientRect();
    return rect.top <= windowHeight * 0.5 && rect.bottom >= windowHeight * 0.5;
  }

  // Function to control the line drawing based on scroll position
  function drawLine() {
    var scrollPosition = $window.scrollTop();

    // Calculate the total height of all .approach-tile elements
    var lastTileOffset = $tiles.last().offset().top + $tiles.last().outerHeight();

    // Calculate the scrollable area based on document height and window height
    var scrollableArea = lastTileOffset - windowHeight;

    // Calculate the percentage of the page that has been scrolled
    var scrollPercent = Math.min(1, Math.max(0, scrollPosition / scrollableArea));  // Ensure it stays between 0 and 1

    // Slow down the scroll drawing effect by adjusting the speed factor
    var slowedScrollPercent = scrollPercent; // Keep it consistent without additional scaling

    // If the line is drawn, do not allow it to draw again when scrolling back up
    if (slowedScrollPercent >= 1) {
      lineDrawn = true;
    }

    // Only draw the line if it hasn't completed yet
    if (!lineDrawn) {
      $line.css('height', slowedScrollPercent * 100 + '%');
    }
  }

  // Handle scroll event with throttle to improve performance
  var lastScrollTime = 0;
  var throttleDelay = 10; // Throttle delay in milliseconds

  $window.on('scroll', function() {
    var currentTime = new Date().getTime();

    // Throttle the scroll event to limit function calls
    if (currentTime - lastScrollTime >= throttleDelay) {
      // Check each .approach-tile to add the 'Active' class if it's in the 50% viewport range
      $tiles.each(function() {
        if (isElementInViewport(this) && !jQuery(this).hasClass('active')) {
          jQuery(this).addClass('active');
        }
      });

      // If any tile has the 'active' class, start drawing the line
      if ($tiles.filter('.active').length > 0 && !lineDrawn) {
        drawLine();
      }

      // Update last scroll time
      lastScrollTime = currentTime;
    }
  });

  // Trigger the scroll event initially to handle any initial page load state
  $window.trigger('scroll');

  // Recalculate document height and window height when the window resizes
  $window.on('resize', function() {
    documentHeight = jQuery(document).height();
    windowHeight = $window.height();
  });

	jQuery(window).on('scroll', function() {
        var section = jQuery('.page-section');
        var sectionOffset = section.offset().top; // Top position of the section
        var scrollTop = jQuery(window).scrollTop(); // Current scroll position
        var windowHeight = jQuery(window).height(); // Height of the viewport

        // Check if the section's top is within 20vh of the viewport
        if (scrollTop + (0.25 * windowHeight) >= sectionOffset) {
            jQuery('body').addClass('white-color');
            jQuery('header').addClass('shrink');
        } else {
            jQuery('body').removeClass('white-color');
            jQuery('header').removeClass('shrink');
        }
    });

    (function() {
    // Only run if on desktop (screen width > 1024px)
    if (window.innerWidth <= 1024) return;

    var cursor = {
        delay: 8,
        _x: 0,
        _y: 0,
        endX: (window.innerWidth / 2),
        endY: (window.innerHeight / 2),
        cursorVisible: true,
        cursorEnlarged: false,
        $dot: jQuery('.cursor-dot'),
        $outline: jQuery('.cursor-dot-outline'),
        dotSize: 0,
        outlineSize: 0,

        init: function() {
            // Set up element sizes
            this.dotSize = this.$dot.outerWidth();
            this.outlineSize = this.$outline.outerWidth();

            this.setupEventListeners();
            this.animateDotOutline();
        },

        setupEventListeners: function() {
            var self = this;

            // Cache common selectors to improve performance
            var $document = jQuery(document);
            var $anchors = jQuery('a');

            // Anchor hovering
            $anchors.on('mouseover mouseout', function(event) {
                self.cursorEnlarged = event.type === 'mouseover';
                self.toggleCursorSize();
            });

            // Click events
            $document.on('mousedown mouseup', function(event) {
                self.cursorEnlarged = event.type === 'mousedown';
                self.toggleCursorSize();
            });

            // Mousemove event
            $document.on('mousemove', function(e) {
                // Show the cursor
                self.cursorVisible = true;
                self.toggleCursorVisibility();

                // Position the dot
                self.endX = e.pageX;
                self.endY = e.pageY;
                self.$dot.css({
                    top: self.endY + 'px',
                    left: self.endX + 'px'
                });
            });

            // Hide/show cursor on mouse enter/leave
            $document.on('mouseenter mouseleave', function(event) {
                self.cursorVisible = event.type === 'mouseenter';
                self.toggleCursorVisibility();
                var opacity = self.cursorVisible ? 1 : 0;
                self.$dot.css('opacity', opacity);
                self.$outline.css('opacity', opacity);
            });
        },

        animateDotOutline: function() {
            var self = this;
            self._x += (self.endX - self._x) / self.delay;
            self._y += (self.endY - self._y) / self.delay;
            self.$outline.css({
                top: self._y + 'px',
                left: self._x + 'px'
            });

            requestAnimationFrame(this.animateDotOutline.bind(self));
        },

        toggleCursorSize: function() {
            var scale = this.cursorEnlarged ? 0.75 : 1;
            var outlineScale = this.cursorEnlarged ? 1.5 : 1;

            this.$dot.css('transform', 'translate(-50%, -50%) scale(' + scale + ')');
            this.$outline.css('transform', 'translate(-50%, -50%) scale(' + outlineScale + ')');
        },

        toggleCursorVisibility: function() {
            var opacity = this.cursorVisible ? 1 : 0;
            this.$dot.css('opacity', opacity);
            this.$outline.css('opacity', opacity);
        }
    };

    cursor.init();
})();

